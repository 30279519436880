import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';

const reducer = combineReducers(rootReducer);

const actionSanitizer = action =>
  action.type === 'SET_EKTP_IMG' && action.value
    ? { ...action, value: '<< BASE64 IMG >>' }
    : action;

const appReducer = (state, action) => {
  return reducer(action.type === 'RESET_LOGOUT' ? undefined : state, action);
};

const sanitize =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionSanitizer,
    stateSanitizer: state =>
      state.loanApply.ektpImg
        ? {
            ...state,
            loanApply: {
              ...state.loanApply,
              ektpImg: '<< BASE64 IMG >>'
            }
          }
        : state
  });

const composeEnhancers =
  process.env.NODE_ENV === 'development' ? sanitize ?? compose : compose;

export default () => {
  const store = createStore(
    appReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  return { store };
};
