/* eslint-disable no-useless-escape */
export const Photo_Not_Matched = 'photo_notMatched';
export const Nik_And_Photo_Not_Match = 'nik_andPhotoNotMatch';
export const Photo_Not_Detected = 'photo_NotDetected';
export const Nik_Not_Registered = 'nik_notRegistered';
export const INVALID_DEMOGRAPHIC = 'INVALID_DEMOGRAPHIC';

export const LIVENESS_FAILED = 'liveness_failed';

export const privyErrorMessage = [
  Photo_Not_Matched,
  Nik_And_Photo_Not_Match,
  Photo_Not_Detected,
  Nik_Not_Registered
];

export const OCH_DATE_FORMAT_SUBMISSION = 'YYYY-MM-DDTHH:mm:ss';
export const OCH_DATE_FORMAT_SUBMISSION2 = 'YYYY-MM-DD';

export const headlines = {
  titleHeadline: 'Hi, Kawan Raya!',
  contentText1: 'Bisa lebih murah',
  contentText2: 'Makin pendek jangka waktunya, makin murah bayarnya!',
  contentText3: 'Tinggal pilih nominal yang tersedia',
  contentText4:
    'Pinjaman hingga 25 juta, Pinang bantu sediakan pilihan nominalnya.',
  contentText5: 'Bayar tagihan bisa dipercepat',
  contentText6:
    'Bisa bayar sebelum batas pelunasan buat hindari uang terpakai belanja.'
};

export const EKYC_FAILED_CONTACT_CODES = [1000, 4000, 5001, 5002, 5004];
export const FAILED_SELFI_DIRECT_PREV = [
  'INVALID_DEMOGRAPHIC',
  'privysys_fail',
  'contact-cs'
];
export const ERROR_EXCEPTION_HANDLING = [
  'system',
  'connection',
  'contact-cs',
  'DIG_SIG_FAIL',
  'DISBURSE_FAIL',
  'number-not-exist',
  'miss_match',
  'registration-failed',
  'limit_usage',
  'data_not_found',
  'nik_disbursement_error',
  'account_disbursement_error',
  'saca_disbursement_error',
  'cif_disbursement_error'
];
export const DISBURSEMENAT_STATUS = {
  SUCCESS: 'S',
  PENDING: 'P',
  FAILED: 'F'
};

export const ERROR_BANNER_NAME = 'error_banner';
export const PROMO_BANNER_NAME = 'promo_banner';
export const DISBURSEMENT_BANNER_NAME = 'disbursement_banner';
export const USER_LOGIN_DATA = 'USER_LOGIN_DATA';
export const PARTNER_ID = 'PARTNER_ID';
export const COMPANY_NAME_ALTERNATIVE = 'Lainnya';
export const SCHEME_CODE_PNNEW = 'PNNEW';
export const SCHEME_CODE_PYLTR = 'PYLTR';
export const SCHEME_CODE_PLNEW = 'PLNEW';
export const SCHEME_CODE_PNANG = 'PNANG';
export const SCHEME_CODE_KASBON = 'KSBON';
export const SCHEME_CODE_PMI = 'PMI';
export const DEVICE_INFORMATION = 'DEVICE_INFORMATION';
export const RAYA_APPS_DATA = 'RAYA_APPS_DATA';
export const CODE_RAYA_APPS = 'CODE_RAYA_APPS';

export const ERROR_VIDA = 'ERROR';
export const COMPLETED_VIDA = 'COMPLETED';
export const PROCESSING_VIDA = 'PROCESSING';
export const DIG_SIGN_COM_1 = 'DIG_SIGN_COM_1';
export const VIDA = 'VIDA';
export const DIG_SIG_FAIL = 'DIG_SIG_FAIL';
export const DIG_SIG_FAIL7 = 'DIG_SIG_FAIL7';
export const PIC_NOT_MATCHED = 'PIC_NOT_MATCHED';

export const regexMotherName =
  /^(?!.*^(m\s*a\s*m\s*a|b\s*u\s*n\s*d\s*a|i\s*b\s*u\s*n\s*d\s*a|i\s*b\s*u|e\s*m\s*a\s*k|m\s*a\s*m\s*a\s*h)(\s|$))/;
export const regexReferralCode = /^(?!.*[^a-zA-Z0-9])/;
export const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const regexNameKTP = /^(?![0-9 ]+$)[A-Za-z0-9 \-']*$/; //can't fill only number
export const MONTH_LIST = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'July',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember'
];
export const MONTH_LIST_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Agu',
  'Sep',
  'Okt',
  'Nov',
  'Des'
];
export const addressPattern = new RegExp('^[a-zA-Z\\d\\-,./\\s]+$');
export const companyAddressPattern = /^(?!^\d+$)(?!^\d)(?!.*[!@#$%^&*+?=]).+$/;
export const companyNamePattern = /^[a-zA-Z][a-zA-Z0-9,\.()\s]*$/;
export const npwpPattern = /^(?!([0-9])\1+$)[0-9]+$/
// eslint-disable-next-line quotes
export const nameWithoutTitlePattern = new RegExp("^[a-zA-Z\\s']+$");

export const delayMiliSecond = 180000;
export const RETIRED_EMPLOYEE_AGE = 55;
export const FIRST_APPLY_START = 'FIRST_APPLY_START';

export const CODE_AUTH_FLOW_RAYA_0001 = '0001';
export const CODE_AUTH_FLOW_RAYA_0002 = '0002';
export const CODE_AUTH_FLOW_RAYA_0003 = '0003';
export const CODE_AUTH_FLOW_RAYA_0004 = '0004';
export const CODE_AUTH_FLOW_RAYA_0005 = '0005';
export const CODE_AUTH_FLOW_RAYA_0006 = '0006';
export const CODE_AUTH_FLOW_RAYA_0007 = '0007';
export const CODE_AUTH_FLOW_RAYA_0008 = '0008';
export const PHONE_PREFIX_62TO08 = '62to08';
